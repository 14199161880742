<template>
  <div class="wrapper">
    <StSkeletonLoader
      class="image-skeleton"
      height="146px"
      bg-color="#2D2D39"
      radius="var(--spacing-100)"
      :opacity="0.28"
    />
    <div class="bottom">
      <StSkeletonLoader
        bg-color="#2D2D39"
        height="12px"
        width="120px"
        :opacity="0.28"
      />
      <StSkeletonLoader
        bg-color="#2D2D39"
        height="8px"
        width="70px"
        :opacity="0.28"
      />
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  padding-top: var(--spacing-050);
}

.bottom {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
  justify-content: center;

  height: 40px;
  padding-top: var(--spacing-100);
}
</style>
